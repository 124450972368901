import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import arrow from 'images/arrow-down.png';
import pointBylinx from 'images/point-bylinx.png';
import pointMerchant from 'images/point-merchant.png';
import pointBuyer from 'images/point-buyer.png';
import heroIllustration from 'images/hero-illustration.png';
import logo from 'images/black-bylinx-logo.png';
import flowX from 'images/flow-x.png';
import flowY from 'images/flow-y.png';

import logoAnterja from 'images/logo-anteraja.png';
import logoJne from 'images/logo-jne.png';
import logosicepat from 'images/logo-sicepat.png';
import logoTiki from 'images/logo-tiki.png'
import logoGrab from 'images/logo-grabexpress.png';

import logoDuitku from 'images/duitku-blue-logo.png';


function Landing() {

  const navigate = useNavigate();

  const aboutUsRef = useRef(null);
  const contactUsRef = useRef(null);

  const COORDINATES = [-6.232985591031158, 106.8312885855617];


  const scrollTo = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView()
    }
  }

  return (
    <div className="relative min-h-screen w-full">

      <Header />

      <div className="w-full pb-200 lg:pb-80">
        <div className="center flex-col pt-24">

          <div className="relative flex flex-col lg:flex-row  w-full max-w-6xl h-216  sm:h-200 lg:h-150 ">

            <div className="mb-5 px-6 lg:px-0 pt-10 lg:pt-20">
              <h1 className="font-display text-5xl text-primary leading-20 mb-10">Transaksi <span className="text-secondary">Aman Nyaman</span>
                <br /> Pakai Bylinx
              </h1>
              <p className="w-full max-w-sm sm:mb-20 lg:mb-24">
              Cara baru transaksi online yang gak repot, tetep aman dan lebih nyaman, Pakai Bylinx.id sekarang!
              </p>
              <Button label="Pelajari Lebih Lanjut" url='/tutorial' className="bg-secondary w-72 hidden sm:flex"
                onClick={() => navigate('/tutorial', { replace: false })} />
            </div>

            <div className="absolute -bottom-20 sm:-bottom-10 lg:bottom-auto  w-full overflow-hidden -z-10">
              <div className="inline-block relative -right-1/2">
                <img className="relative -left-1/2 sm:-left-1/3 w-232 lg:w-248 max-w-none h-full" src={heroIllustration} alt="illustration" />
              </div>
            </div>

          </div>


          <div className="animate-bounce center py-5" role="button"
            onClick={() => scrollTo(aboutUsRef)}>
            <img src={arrow} className="w-8" />
          </div>
        </div>




        <div ref={aboutUsRef} className="center py-32 bg-gray-50">
          <div className="center flex-col w-full max-w-6xl px-6 lg:px-0">
            <h2 className='font-display text-2xl mb-10'>Apa itu Bylinx.id?</h2>
            <p className='w-full max-w-3xl text-center mb-20'>
              Platform transaksi jual-beli barang on demand yang dapat membantu penjual dan pembeli
              bertransaksi secara mudah, aman, dan cepat.
            </p>
            <div className='flex flex-col sm:flex-row items-center sm:justify-between space-y-14 sm:space-y-0 sm:space-x-10 w-full'>
              <div className='center flex-col w-full sm:max-w-xs'>
                <img className="w-16 mb-5" src={pointBylinx} alt="icon" />
                <div className="font-bold mb-5">Bylinx.id</div>
                <p className='text-center text-sm w-full max-w-xs'>
                  Platform yang membantu penjual dan pembeli bertransaksi serta mengirim dan melacak pengiriman dengan mudah
                </p>
              </div>
              <div className='center flex-col w-full sm:max-w-xs'>
                <img className="w-16 mb-5" src={pointMerchant} alt="icon" />
                <div className="font-bold mb-5">Penjual</div>
                <p className='text-center text-sm w-full max-w-xs'>
                  Jual barang di bylinx.id gak perlu repot, cukup dengan satu link kamu bisa jual dan terima dana penjualan kamu dengan mudah
                </p>
              </div>
              <div className='center flex-col w-full sm:max-w-xs'>
                <img className="w-16 mb-5" src={pointBuyer} alt="icon" />
                <div className="font-bold mb-5">Pembeli</div>
                <p className='text-center text-sm w-full max-w-xs'>
                  Sekarang gak perlu takut lagi ditipu, barang tidak sampai, susah komplain, karena satu link dari bylinx.id 
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className="center py-20 bg-green-300">
          <div className="w-full max-w-6xl">
            <div className="center flex-col w-full px-6 lg:px-0">
              <img className="w-40 mb-20 sm:mb-5" src={logo} alt="bylinx logo" />
              <img className="hidden sm:block w-4/5" src={flowX} alt="flow" />
              <img className="block sm:hidden w-1/2 mb-10" src={flowY} alt="flow" />
              <Button label="Selengkapnya" className="bg-secondary w-60"
                onClick={() => navigate('/tutorial', { replace: false })} />

              <div className="center flex-col bg-black w-full rounded-3xl px-6 py-20 sm:py-10 mt-20">
                <h2 className="font-display text-3xl text-primary text-center">Mulai Transaksimu Sekarang Dengan Bylinx.id</h2>\
                <p className="text-white text-sm w-full max-w-lg text-center mb-10">
                  Membantu Anda(penjual dan pembeli) ketika melakukan proses jual beli dan
                  tracking barang dalam satu platform
                </p>
                <Button label="Jualan Sekarang"
                  onClick={() => window.location.href = 'https://merchant.bylinx.id/signup/'} />
              </div>

            </div>
          </div>
        </div>


        <div className="flex flex-col items-center bg-gray-50 py-20">
          <div className="flex flex-col items-center mb-16">
            <div className='font-bold text-xl lg:text-2xl mb-2 lg:mb-2'>Pembayaran Didukung Oleh</div>
            <img className="w-32 lg:w-40 m-5" src={logoDuitku} alt="logo duitku" />
          </div>
          <div className="flex flex-col items-center">
            <div className='font-bold text-xl lg:text-2xl mb-2 lg:mb-2'>Pengiriman Didukung Oleh</div>
            <div className="flex flex-row flex-wrap justify-center mt-5 ">
              <img className="w-32 lg:w-40 m-5" src={logoGrab} alt="logo grab express" />
              <img className="w-32 lg:w-40 m-5" src={logoAnterja} alt="logo anteraja" />
              <img className="w-32 lg:w-40 m-5" src={logoJne} alt="logo jne" />
              <img className="w-32 lg:w-40 m-5" src={logosicepat} alt="logo sicepat" />
              <img className="w-32 lg:w-40 m-5" src={logoTiki} alt="logo tiki" />
            </div>
          </div>

        </div>



        <div className="center py-32">
          <div className="w-full max-w-6xl px-6 lg:px-0">
            <h2 className="font-display text-4xl mb-10">PT. Unicorn International Indonesia</h2>
            <div className="flex flex-col sm:flex-row items-center">
              <p className="text-sm w-full flex-1 mb-20 sm:mb-0">
                Coming with us, get on board, and figure out how to transform your business into the digital era.
                Unicorn International Indonesia keeps developing great digital projects to enhances
                your digital experiences. We work on digital transformation to build a better, faster,
                and simpler platform to evolve your business growth.
              </p>
              <div className='flex justify-center flex-1 w-full'>
                <Button className="w-full sm:w-60 bg-primary" label="Lihat kami lebih dekat" 
                  onClick={() => navigate('/about', { replace: false })}/>
              </div>
            </div>
          </div>
        </div>

        <div ref={contactUsRef} className="center bg-gray-50">
          <div className="flex flex-col sm:flex-row w-full max-w-6xl">
            <div className="flex-1 py-20 px-6 lg:px-0">
              <h2 className="font-display text-4xl mb-10">Kontak Kami</h2>
              <p className="w-full max-w-sm mb-5 text-sm">
                Jika ada pertanyaan lebih lanjut terkain Bylinx.id silakan menghubungi <b>cs@bylinx.id </b>
                dan kami akan segera merespon pertanyaan yang masuk pada jam operasional kantor kami yaitu:
              </p>
              <div className="text-sm">
                Jam Operasional: <br />
                <b>Senin - Jumat: 09:00 - 17:00 WIB</b>
              </div>
            </div>
            <div className="w-full sm:flex-1 h-80 sm:h-auto">
              <MapContainer className="w-full h-full z-0"
                center={COORDINATES}
                zoom={16} scrollWheelZoom={false}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

                />
                <Marker position={COORDINATES}>
                  <Popup>
                    <b>Menara Karya</b><br />
                    Jl. HR. Rasuna Said Blok X-5 Kav. 1-2, Setiabudi Kuningan Jakarta Selatan DKI Jakarta 12950
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

}
export default Landing;