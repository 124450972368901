import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'components/Page';
import tutorial from 'images/tutorial.png';
import right from 'images/right.png';
import tutorialJson from 'data/tutorial.json';


function Tutorial() {

  const [sellerList, setSellerList] = useState([]);
  const [buyerList, setBuyerList] = useState([]);

const navigate = useNavigate();

  useEffect(() => {

    setSellerList([]);
    setBuyerList([]);
    for(let i =0; i < tutorialJson.length; i++){

      const tutorial = tutorialJson[i];
      if(tutorial.category === 'seller'){
        setSellerList(s => [...s, tutorial]);
      }
      else if(tutorial.category === 'buyer'){
        setBuyerList(b => [...b, tutorial]);
      }

    }

  }, []);


  const renderTutorialItem = (i, category, title, slug) => (
    <div key={i} className="border-b border-b-black mb-5">
      <a href={`/tutorial/${category}/${slug}`}>
        <div className="flex flex-row justify-between items-center py-2 mb-5 cursor-pointer">
          <div className="font-bold text-primary">{title}</div>
          <img className="w-5" src={right} alt="more"/>
        </div>
      </a>
    </div>
  );

  return(
    <Page>
      <div className="center flex-col items-center md:flex-row md:px-40 py-20 ">
        <img className="w-36 mb-10 md:mb-0" src={tutorial} alt="tutorial"/>
        <div className="flex-1 center flex-col">
          <h1 className="font-display text-4xl mb-5">Tutorial</h1>
          <p>Berikut tutorial penggunaan Bylinx</p>
        </div>
      </div>
      <div className="mb-20">
        <h2 className="text-2xl font-bold mb-10">Seller</h2>
        {
          sellerList.map((tutorial, i) => renderTutorialItem(i, 'seller', tutorial.title, tutorial.slug))
        }
      </div>
      <div>
        <h2 className="text-2xl font-bold mb-10">Buyer</h2>
        {
          buyerList.map((tutorial, i) => renderTutorialItem(i, 'buyer', tutorial.title, tutorial.slug))
        }
      </div>

    </Page>
  );
}
export default Tutorial;