import Header from "./Header";
import Footer from "./Footer";

function Page({children}) {

  return(
    <div className="relative min-h-screen">
      <div className="w-full">
        <Header/>
        <div className="center">
          <div className="w-full max-w-6xl pt-24 px-6 lg:px-0 pb-200 lg:pb-80">
            {children}
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}
export default Page;