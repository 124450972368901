import Page from 'components/Page';


function About() {


  return(
    <Page>
      <div className="center py-20">
        <h1 className="font-display text-4xl">About Us</h1>
      </div>
      <p className="mb-10">
        <b>Bylinx.id</b> adalah Platform transaksi jual-beli barang on demand yang dapat membantu penjual dan pembeli 
        bertransaksi secara mudah, aman dan cepat.
      </p>
      <p className="mb-10">
      <b>Bylinx.id</b> merupakan salah satu produk dari <a className="text-primary font-bold" href="https://unicornint.com">PT. Unicorn International Indonesia</a> yang secara konsisten 
        terus mengembangkan proyek digital yang hebat untuk meningkatkan pengalaman digital Anda.
      </p>
      <p className="mb-10">
        Fokus dari <a className="text-primary font-bold" href="https://unicornint.com">PT. Unicorn International Indonesia</a> adalah untuk terus melakukan transformasi digital 
        yang bertujuan untuk membangun platform yang lebih baik, cepat dan lebih sederhana 
        untuk mengembangkan pertumbuhan bisnis anda.
      </p>
    </Page>
  );
}
export default About;