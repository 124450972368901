import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Page from 'components/Page';
import faq from 'images/faq.png';
import more from 'images/more.png';
import less from 'images/less.png';
import faqJson from 'data/faq.json';



function Faq() {

  const [faqData, setFaqData] = useState([]);

  useEffect(() => {

    setFaqData([]);
    for(let i =0; i < faqJson.length; i++){
      const faq = faqJson[i];
      faq.expand = false;
      setFaqData(d => [...d, faq]);
    }

  }, []);

  const handleExpand = (selectedIndex) => {

    setFaqData(prevState => {
      const newState = prevState.map((obj, i) => {
        
        if(selectedIndex === i){
          return {...obj, expand: !obj.expand};
        }

        return {...obj, expand: false};
      });

      return newState;
    });
    
  }



  const renderFaqItem = (i, question, answer, expand) => (
    <div key={i} className="border-b border-b-black mb-5">
      <div className="flex flex-row justify-between items-center py-2 mb-5 cursor-pointer"
        onClick={() => handleExpand(i)}>
        <div className="font-bold text-primary">{question}</div>
        <img className="w-5" src={expand ? less : more} alt="more"/>
      </div>
      {
        expand &&
        <div className="markdown text-sm my-5 list-disc">
          <ReactMarkdown>{answer}</ReactMarkdown>
        </div>
      }
    </div>
  )


  return(
    <Page>
      <div className="center flex-col items-center md:flex-row md:px-40 py-20 ">
        <img className="w-36 mb-10 md:mb-0" src={faq} alt="faq"/>
        <div className="flex-1 center flex-col">
          <h1 className="font-display text-4xl mb-5">FAQ</h1>
          <p>Seputar pertanyaan yang paling sering ditanyakan</p>
        </div>
      </div>

      <div className="mb-40">
        {
          faqData.map((faq, i) => renderFaqItem(i, faq.question, faq.answer, faq.expand))
        }
      </div>
    </Page>
  );

}
export default Faq;