import React, { useState, useEffect } from 'react';


const MOBILE = 640;
const DESKTOP = 1024;

const useViewport = () => {

    const [width, setWidth] = useState(window.innerWidth);


    useEffect(() => {

        const handleWindowResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleWindowResize);

        // componentWillUnmount
        return () => window.removeEventListener('resize', handleWindowResize);

    }, []);

    return { width };
}
export {useViewport as default, MOBILE, DESKTOP};