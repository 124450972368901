import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Page from "components/Page";


function NotFound() {

  const navigate = useNavigate();

  return(
    <Page>
      <div className="flex flex-row justify-center items-center w-full">
        <div className="my-20">
          <div className="text-6xl">:(</div>
          <div className="my-2">Halaman yang anda tuju tidak ada.</div>
          <Button className="bg-primary h-12 w-32" label="Kembali"
            onClick={() => navigate('/')}/>
        </div>
      </div>
    </Page>
  );
}
export default NotFound;