
function Button({
  label = 'Action',
  className = 'w-60 bg-primary',
  onClick = () => {}
}) {

  return(
    <div role="button" className={`${className} center h-12 text-white font-bold rounded-xl cursor-pointer`}
      onClick={() => onClick()}>
      {label}
    </div>
  );

}
export default Button;