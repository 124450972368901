import bylinxLogo from 'images/gray-bylinx-logo.png';
import duitkuLogo from 'images/duitku-logo.png';
import whatsappLogo from 'images/whatsapp.png';
import instagram from 'images/instagram.png';
import tiktok from 'images/tiktok.png';
import twitter from 'images/twitter.png';

import logoAnterja from 'images/logo-anteraja.png';
import logoJne from 'images/logo-jne.png';
import logosicepat from 'images/logo-sicepat.png';
import logoTiki from 'images/logo-tiki.png'
import logoGrab from 'images/logo-grabexpress.png';



function Footer() {

  return(
    <div className="absolute bottom-0 center w-full bg-black text-gray-400 text-sm h-200 lg:h-80 mt-auto">

      <div className="w-full max-w-6xl px-6 lg:px-0">
        
        <div className="flex flex-col lg:flex-row space-x-0 space-y-10 lg:space-y-0 lg:space-x-20 justify-between">
          <div className="w-full max-w-xs">
            <div className="flex flex-row justify-between mb-5">
              <a href="https://bylinx.id"><img className="w-24" src={bylinxLogo} alt="Bylinx logo" /></a>
              <div className="flex flex-row items-center">
                <span className="text-xs text-gray-400 mr-2">Supported by:</span>
                <a href="https://duitku.com"><img className="w-24" src={duitkuLogo} alt="Duitku logo" /></a>
              </div>
            </div>

            <div className='mb-10'>
              <div>Pengiriman didukung oleh:</div>
              <div className="flex flex-row space-x-3 mt-5">
                <img className="w-14" src={logoGrab} alt="logo grab express"/>
                <img className="w-14" src={logoAnterja} alt="logo anteraja"/>
                <img className="w-14" src={logoJne} alt="logo jne"/>
                <img className="w-14" src={logosicepat} alt="logo sicepat"/>
                <img className="w-14" src={logoTiki} alt="logo tiki"/>
              </div>
            </div>




            <div className="mb-2">PT. Unicorn International Indonesia</div>
            <div>
              Menara Karya 28th Floor, Jl. HR. Rasuna Said Blok X-5 Kav. 1-2, Kuningan Timur, Setiabudi, Jakarta Selatan, 12950
            </div>
          </div>
          <div className="flex flex-col">
            <div className="font-bold mb-5">Tentang Kami</div>
            <a className="mb-2 hover:text-white text-white" href="/about">Tentang Bylinx</a>
            <a className="mb-2 hover:text-white text-white" href="/terms">Terms of Services</a>
          </div>
          <div className="flex flex-col">
            <div className="font-bold mb-5">Bantuan</div>
            <a className="mb-2 hover:text-white text-white" href="/tutorial">Tutorial</a>
            <a className="mb-2 hover:text-white text-white" href="/faq">FAQ</a>
          </div>
          <div>
            <div className="font-bold mb-5">Kontak Kami</div>
            <div className="flex flex-row items-center mb-2">
              <img className="w-5 mr-2" src={whatsappLogo} alt="whatsapp"/>
              <div className='text-white'>+62 85715544327</div>
            </div>
            <a className='text-white' href="mailto:cs@bylinx.id">cs@bylinx.id</a>
            <div className="flex flex-row space-x-3 mt-10">
              <a href="https://twitter.com/bylinx-id"><img className="w-4" src={twitter} alt="twitter"/></a>
              <a href="https://twitter.com/bylinx-id"><img className="w-4" src={instagram} alt="instagram"/></a>
              <a href="https://twitter.com/bylinx-id"><img className="w-4" src={tiktok} alt="tiktok"/></a>
            </div>
          </div>



        </div>


      </div>
    </div>
  );


}
export default Footer;