import {useState, useEffect, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Page from 'components/Page';
import tutorialJson from 'data/tutorial.json';


function TutorialDetail(){

  const { category, slug } = useParams();


  const [pageExist, setPageExist] = useState(true);
  const [titlePage, setTitlePage] = useState();
  const [content, setContent] = useState('');


  useEffect(() => {

    findTitlePage();
    
    loadContent(category, slug, 
      (data) => {
        setContent(data);
      });


  }, []);

  const findTitlePage = () => {

    for(let i =0; i < tutorialJson.length; i++){

      const tutorial = tutorialJson[i];
      if(tutorial.slug === slug){
        setTitlePage(tutorial.title);
        return;
      }

    }

    setPageExist(false);

  }

  const loadContent = async (category, slug, onLoaded) => {

    const data = await import(`pages/tutorial/${category}/${slug}/${slug}.md`);
    
    fetch(data.default).then(res => res.text())
      .then(res => onLoaded(res));

  }
  

  return(
    <Page>
      <div className="inline-flex my-10 w-full border-b pb-5">
        <a className="text-primary font-bold" href="/tutorial">Tutorial</a>
        <span className="mx-2">/</span>
        <div className="font-bold">{titlePage}</div>
      </div>

      <div className="markdown mb-20">
        <ReactMarkdown children={content} />
      </div>

    </Page>
  );

}
export default TutorialDetail;