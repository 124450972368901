import { useState } from 'react';
import Hamburger from 'hamburger-react';
import useViewport, { DESKTOP } from 'util/Viewport';
import logo from 'images/bylinx-logo.png';


function Header() {

  const [isOpen, setOpen] = useState(false);  // mobile only

  const { width } = useViewport();




  const renderHeaderForMobile = () => (
    <div className="fixed top-0 inset-x-0 z-50 px-6 bg-white">
      <div className="flex flex-row items-center justify-between w-full h-24">
        <a href="/"><img className='w-32 mr-10' src={logo} alt="Bylinx logo"/></a>
        <Hamburger toggled={isOpen} toggle={setOpen} 
          direction="right" 
          rounded={true}
        />
      </div>
      <div className={isOpen ? 'block' : 'hidden'}>
        <div className="center flex-col py-5 flex-1">
          <a href="/tutorial" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">Tutorial</a>
          <a href="/faq" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">FAQ</a>
          <a href="/about" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">Tentang Kami</a>

          
          <a href="https://merchant.bylinx.id/login/" 
            className="px-4 py-4 text-secondary hover:text-primary font-bold" role="button">Masuk</a>
          <a href="https://merchant.bylinx.id/signup/"  
            className="w-32 h-10 center bg-primary hover:bg-green-500 text-white font-bold rounded-xl cursor-pointer" role="button">Daftar</a>

        </div>
      </div>
    </div>
  );

  const renderHeaderForDesktop = () => (
    <div className="fixed top-0 inset-x-0 center z-50 h-24 bg-white">
      <div className="w-full h-full flex flex-row items-center max-w-6xl">
        <a href="/"><img className='w-32 mr-10' src={logo} alt="Bylinx logo"/></a>
        <div className="flex flex-row flex-1">
          <a href="/tutorial" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">Tutorial</a>
          <a href="/faq" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">FAQ</a>
          <a href="/about" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">Tentang Kami</a>
        </div>
        
        <div className="flex flex-row">
          <a href="https://merchant.bylinx.id/login/" 
            className="px-4 py-2 text-secondary hover:text-primary font-bold" role="button">Masuk</a>
          <a href="https://merchant.bylinx.id/signup/"  
            className="w-32 h-10 center bg-primary hover:bg-green-500 text-white font-bold rounded-xl cursor-pointer" role="button">Daftar</a>
        </div>
      </div>
    </div>
  );
  

  return width > DESKTOP ? renderHeaderForDesktop() : renderHeaderForMobile();

}
export default Header;