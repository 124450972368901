import Page from 'components/Page';

function Terms() {

  return(
    <Page>
      <div className="center py-20">
        <h1 className="font-display text-4xl">Terms and Conditions</h1>
      </div>
    </Page>
  );

}
export default Terms;