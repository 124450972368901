import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Landing from 'pages/Landing';
import Faq from 'pages/Faq';
import Terms from 'pages/Terms';
import Tutorial from 'pages/Tutorial';
import TutorialDetail from 'pages/TutorialDetail';
import About from 'pages/About';
import NotFound from 'pages/NotFound';
import './App.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="about" element={<About/>}/>
        <Route path="tutorial/:category/:slug" element={<TutorialDetail/>}/>
        <Route path="tutorial" element={<Tutorial/>}/>
        <Route path="terms" element={<Terms/>}/>
        <Route path="faq" element={<Faq/>}/>
        <Route path="/" element={<Landing/>}/>
        <Route path="*" element={<NotFound/>}/>
      </Routes>
    </Router>
  );
}

export default App;
